/* eslint-disable no-new */
import addInputMaskPhone from './handlers/form-elements/phone-mask'
import CustomSelect from './handlers/form-elements/custom-select'
import MultipleCustomSelect from './handlers/form-elements/multiple-custom-select'
import password from './handlers/form-elements/password'

import formSender from './handlers/form-sender'
import { modalHandler, modalGallery } from './handlers/modals'
import initSliders from './handlers/sliders'
import accordeon from './handlers/accordeon'
import filter from './handlers/filter'
import common from './handlers/common'
import catalog from './handlers/catalog'
// import chooseCity from './handlers/choose-city'
import SimpleBar from 'simplebar'
import initCustom from './handlers/custom'

function initApp() {
  window.$body = $('.js-body')
  common.init()
  /**
   *  Добавление масок к номерам телефонов
   */
  addInputMaskPhone()

  initCustom()

  // chooseCity.init()

  /**
   * Обработчик поведение списков выбора
   */
  $('.js-custom-select').each(function () {
    new CustomSelect($(this))
  })

  /**
   * Обработчик поведение множественных списков выбора
   */
  $('.js-custom-multiple-select').each(function () {
    new MultipleCustomSelect($(this))
  })

  /**
   * Обработчик отправки форм
   */
  formSender.init()

  /**
   * Инициализация модальных окон
   */
  modalHandler.init()

  /**
   * Инициализация модальных окон для просмотра фото
   */
  modalGallery.init()

  /**
 * Инициализация кастомного скролбара
 */
  $('.js-custom-bar').each(function () {
    new SimpleBar($(this)[0])
  })

  /**
 * Инициализация слайдера
 */
  initSliders()

  /**
 * Инициализация аккордеона
 */
  accordeon.init()

  /**
 * Инициализация фильтра
 */
  filter.init()

  /**
 * Инициализация кнопки показать/скрыть пароль
 */
  password.init()

  /**
 * Инициализация каталога в мобильной версии
 */
  catalog.init()
}

export { initApp }

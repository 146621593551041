const password = {
  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    $(document).on('click', '.js-toggle-password', function () {
      const $input = $('.js-input-password')

      if ($input.attr('type') === 'password') {
        $(this).addClass('active')
        $input.attr('type', 'text')
      } else {
        $(this).removeClass('active')
        $input.attr('type', 'password')
      }
    })
  }
}

export default password

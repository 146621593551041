const accordeon = {
  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    const self = this

    $(document).on('click', '.js-toggle-accordeon', function() {
      const $body = $(this).siblings('.js-accordeon-body')
      $body.slideToggle()
      $(this).toggleClass('active')
    })

    $(window).on('resize', function() {
      self.toggleAccordeon()
    })

    if (window.innerWidth <= 768) $('.js-accordeon').addClass('js-toggle-accordeon')

    $(document).on('click', '.js-toggle-description', function() {
      const isOpen = $(this).hasClass('active')
      $('.js-toggle-description').removeClass('active')
      $('.js-description').slideUp()
      console.log($(this), isOpen)

      if (!isOpen) {
        $(this).addClass('active')
        $(this).siblings('.js-description').slideDown()
      }
    })
  },

  toggleAccordeon() {
    if (window.innerWidth <= 768) $('.js-accordeon').addClass('js-toggle-accordeon')
    else $('.js-accordeon').removeClass('js-toggle-accordeon')
  }
}

export default accordeon

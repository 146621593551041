const backendHandlers = {
	init() {
		this.eventHandlers()
	},

	eventHandlers() {
		$('.regg').click(function () {
			$('.regform').show()
			$('.auth_form').hide()
		})

		$('.authh').click(function () {
			$('.auth_form').show()
			$('.regform').hide()
		})

		$(document).ready(function () {
			/* Favorites */
			$('.js-like-btn').on('click', function (e) {
				let favorID = $(this).attr('data-item')

				if(!favorID) {
					const productSectionId = $(this).closest('.section-product').attr('id');
					const productSectionIdSplitted = productSectionId.split('_');
					const productSectionIdSplittedPosition = productSectionIdSplitted.length - 1;
					favorID = productSectionIdSplitted[productSectionIdSplittedPosition];
				}

				if ($(this).hasClass('like-btn--active')) {
					console.log('has class active - lets remove from fav and remove class')
					var doAction = 'delete'
					//$('.js-like-btn[data-item="' + favorID + '"]').removeClass('like-btn--active')
					$(this).removeClass('like-btn--active')
				} else {
					console.log('there is no active class - add to fav and add class')
					var doAction = 'add'
					//$('.js-like-btn[data-item="' + favorID + '"]').addClass('like-btn--active')
					$(this).addClass('like-btn--active')
				}
				addFavorite(favorID, doAction)
			})

			/* Favorites */
			$('.dist_form').on('click', function (e) {
				e.preventDefault()
				var form_dist = document.querySelector('.formpost')
				var formData = new FormData(form_dist)
				new XMLHttpRequest().open('POST', '/ajax/form.php'),
					$.ajax({
						url: '/ajax/form.php',
						dataType: 'html',
						method: 'post',
						processData: false,
						contentType: false,
						data: formData,
						success: function (e) {
							if (e == 1) {
								alert('Спасибо за обращение')
							} else {
								alert('Обращение не отправлено')
							}
						},
					})
			})
			$('.return_form').on('click', function (e) {
				e.preventDefault()
				$.ajax({
					url: '/ajax/form.php',
					dataType: 'html',
					method: 'post',
					data: {
						name: $('.ret_name').val(),
						phone: $('.ret_phone').val(),
						form_name: $('.ret_name_form').val(),
					},
				})
				alert('Спасибо за обращение')
			})
		})

		/* получаем UUID пользователя для mindbox из куки сайта */
		function getCookie(name) {
			const cookieName = " " + name + "=";
			const decodedCookie = decodeURIComponent(document.cookie);
			const cookieArray = decodedCookie.split(';');

			for (let i = 0; i < cookieArray.length; i++) {
				let cookie = cookieArray[i];
				if (cookie.indexOf(cookieName) === 0) {
					return cookie.substring(cookieName.length, cookie.length);
				}
			}
			return "";
		}

		/* Избранное */
		function addFavorite(id, action) {
			var param = 'id=' + id + '&action=' + action
			$.ajax({
				url: '/ajax/favorites.php', // URL отправки запроса
				type: 'POST',
				dataType: 'html',
				data: param,
				success: function success(response) {

					/* отправляем данные о добавлении/удалении в mindbox */
					if(action === 'delete') {
						removeFromWishList(id)
					}
					if(action === 'add') {
						addToWishList(id)
					}

					console.log('success')
					console.log('response is', response)

					// Если Данные отправлены успешно
					if(response) {
						var result = $.parseJSON(response)
						console.log("result is", result)
					}

					let wishCount

					if (result === 1) {
						// Если всё чётко, то выполняем действия, которые показывают, что данные отправлены :)
						wishCount = parseInt($('#want .col').html()) + 1
						$('#want .col').html(wishCount) // Визуально меняем количество у иконки
					}
					if (result === 2) {
						wishCount = parseInt($('#want .col').html()) - 1
						$('#want .col').html(wishCount) // Визуально меняем количество у иконки
					}
				},
				error: function error(jqXHR, textStatus, errorThrown) {
					// Если ошибка, то выкладываем_печаль в консоль
					console.log('Error: ' + errorThrown)
				},
			})


		}

		/*
		добавление, удаление в избранное для mindbox
		 */
		function addToWishList(productId) {
			const deviceUuid = getCookie("mindboxDeviceUUID").trim();
			const apiUrl = `https://api.mindbox.ru/v3/operations/async?endpointId=cosmetika.Website&operation=Website.AddToWishList&deviceUUID=${deviceUuid}`;
			const id = productId;
			// TODO определить product group
			const productGroup = '';
			const currentCard =  document.querySelector(`.product-card[id$="${id}"]`) ? document.querySelector(`.product-card[id$="${id}"]`) : document.querySelector(`.product-card[data-id$="${id}"]`);
			const currentProductSection = document.querySelector(`.section-product[id$="${id}"]`);
			let productPrice;

			if(currentCard) {
				productPrice = currentCard.querySelector('.js-price-new').innerHTML;
				productPrice = parseFloat(productPrice.replace(/[^0-9\.]/g, ''));
				console.log(productPrice);
			}
			if(currentProductSection) {
				productPrice = currentProductSection.querySelector('.js-price-new').innerHTML;
				productPrice = parseFloat(productPrice.replace(/[^0-9\.]/g, ''));
				console.log(productPrice);
			}

			// отправляем  запрос  на  Mindbox  API
			fetch(apiUrl, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					"addProductToList": {
						"product": {
							"ids": {
								"website": `${productId}`
							}
						},
						// "productGroup": {
						// 	"ids": {
						// 		"website": `${productGroup}`
						// 	}
						// },
						"pricePerItem": `${productPrice}`
					}
				})
			})
				.then(response => {
					console.log('response mindbox is', response)
					if (!response.ok) {
						//  Обработка  ошибки
						console.error('Ошибка  при   добавлении   в  Избранное');
						// ...
						return;
					}
					//  Обработка   ответа   от   Mindbox   (например,  обновление   UI   для  отображения  добавления   товара  в  Избранное)
					console.log('Товар  добавлен  в  Избранное');
					// ...
				})
				.catch(error => {
					console.error('Ошибка  при   отправке  запроса  на   Mindbox:',  error);
				});
		}

		function removeFromWishList(productId) {
			console.log('remove from wishList in mindbox')
			const deviceUuid = getCookie("mindboxDeviceUUID").trim();
			const apiUrl = `https://api.mindbox.ru/v3/operations/async?endpointId=cosmetika.Website&operation=Website.RemoveFromWishList&deviceUUID=${deviceUuid}`;
			const id = productId;
			// TODO определить product group
			const productGroup = '';
			const currentCard =  document.querySelector(`.product-card[id$="${id}"]`) ? document.querySelector(`.product-card[id$="${id}"]`) : document.querySelector(`.product-card[data-id$="${id}"]`);
			const currentProductSection = document.querySelector(`.section-product[id$="${id}"]`);

			let productPrice;

			if(currentCard) {
				productPrice = currentCard.querySelector('.js-price-new').innerHTML;
				productPrice = parseFloat(productPrice.replace(/[^0-9\.]/g, ''));
				console.log(productPrice);
			}
			if(currentProductSection) {
				productPrice = currentProductSection.querySelector('.js-price-new').innerHTML;
				productPrice = parseFloat(productPrice.replace(/[^0-9\.]/g, ''));
				console.log(productPrice);
			}

			// отправляем  запрос  на  Mindbox  API
			fetch(apiUrl, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					removeProductFromList: {
						product: {
							ids: {
								website: `${productId}`
							}
						},
						// productGroup: {
						// 	ids: {
						// 		website: `${productGroup}`
						// 	}
						// },
						pricePerItem: `${productPrice}`
					}

				})
			})
				.then(response => {
					if (!response.ok) {
						console.error('Ошибка  при   добавлении   в  Избранное');
						return;
					}
					//  Обработка   ответа   от   Mindbox
					console.log('Товар удален из Избранного в  Mindbox');
				})
				.catch(error => {
					console.error('Ошибка  при   отправке  запроса  на   Mindbox:', error);
				});
		}

		/* Избранное */
		/*  $('.delete').on('click', function () {
		var itemID = $(this).next().attr('data-id');
		$.ajax({
			url: '/ajax/del_item.php',
			dataType: 'html',
			method: 'post',
			data: {
				ID: itemID
			}
		});
		location.reload();
	});
	$('.plus').on('click', function () {
		var count = parseInt($(this).prev().val());
		$(this).prev().val(count + 1);
		$('.quant').change();
	});
	$('.minus').on('click', function () {
		var count = parseInt($(this).next().val());
		if (count > 0) {
			$(this).next().val(count - 1);
			$('.quant').change();
		}
	});
	$('.quant').on('change', function () {
		var quant = $(this).val();
		var id = $(this).parents('.bx-soa-item-properties').next().find('.disnon').attr('data-id');
		$.ajax({
			url: '/ajax/count_item.php',
			dataType: 'html',
			method: 'post',
			data: {
				ID: id,
				QUANT: quant
			}
		});
	});*/

		$('.subm_review').on('click', function (e) {
			e.preventDefault()
			var text = $('.review_text').val()
			if ($('.anon').is(':checked')) var anon = 'Да'

			var id = $('.id').val()
			var dost = $('.dost').val()
			var nedost = $('.nedost').val()
			var rate = $('.rrate:checked').val()

			$.ajax({
				url: '/ajax/add_review.php',
				dataType: 'html',
				method: 'post',
				data: {
					ID: id,
					DETAIL_TEXT: text,
					ANON: anon,
					DOST: dost,
					NEDOST: nedost,
					RATING: rate,
				},
			})
		})

		$(document).on('click', '.to_basket', function (e) {
			e.preventDefault()
			var id = $(this).attr('data-id')

			document
				.querySelectorAll('.bask_count')
				.forEach(counter => counter.innerText = +counter.innerText + 1)

			$.ajax({
				url: '/ajax/add_to_basket.php',
				dataType: 'html',
				method: 'post',
				data: 'ID=' + id + '&ACTION=ADD&QUANTITY=1',
			})
		})

		$(document).on('click', '.to_basket_sect', function (e) {
			e.preventDefault()
			var id = $(this).attr('data-id')
			var link = $(this).parent()

			document
				.querySelectorAll('.bask_count')
				.forEach(counter => counter.innerText = +counter.innerText + 1)

			$(this).hide()
			if (parseInt($(link).find('.counter__value').text().match(/\d+/)) == 0) {
				$(link).find('.counter__value').text('В корзине: 1')
			}
			$(link).find('.counter').show()
			$.ajax({
				url: '/ajax/add_to_basket.php',
				dataType: 'html',
				method: 'post',
				data: 'ID=' + id + '&ACTION=ADD&QUANTITY=1',
			})
		})

		$(document).on('click', '.basket_plus', function (e) {
			e.preventDefault()
			var id = $(this).prev().attr('data-id')
			var num = parseInt($(this).prev().text().match(/\d+/))
			num++

			$(this).prev().text('В корзине: ' + num)

			document
				.querySelectorAll('.bask_count')
				.forEach(counter => counter.innerText = +counter.innerText + 1)

			$.ajax({
				url: '/ajax/add_to_basket.php',
				dataType: 'html',
				method: 'post',
				data: 'ID=' + id + '&ACTION=ADD&QUANTITY=1',
			})
		})

		$(document).on('click', '.basket_minus', function (e) {
			e.preventDefault()
			var id = $(this).next().attr('id')
			var num = parseInt($(this).next().text().match(/\d+/))
			var link = $(this).parent().parent()

			document
				.querySelectorAll('.bask_count')
				.forEach(counter => counter.innerText = +counter.innerText - 1)

			num--
			if (num > 0) {
				$(this)
					.next()
					.text('В корзине: ' + num)
			}
			if (num == 0) {
				$(link).find('.counter').hide()
				$(link).find('.to_basket_sect').show()
				$(this)
					.next()
					.text('В корзине: ' + num)
				/*$(link).html('<a class="btn btn--base to_basket_sect" href="javascript:void(0)" rel="nofollow" data-id='+dataid+'> В корзину </a>');*/
			}
			$.ajax({
				url: '/ajax/add_to_basket.php',
				dataType: 'html',
				method: 'post',
				data: 'ID=' + id + '&ACTION=DELETE&QUANTITY=' + $(this).next().text(),
			})
		})

		$('.flr').on('click', function (e) {
			e.preventDefault()
			$.ajax({
				url: '/ajax/subscribe.php',
				dataType: 'html',
				method: 'post',
				data: {
					EMAIL: $('.js-input-email').val(),
				},
			})
		})
		$('.rrate').on('click', function () {
			var id = $(this).val()
			var id1 = parseInt(id) + 1

			$(this).next().children().hide()
			$(this).next().children().next().css('display', 'block')
			while (id > 0) {
				if (
					$('.r' + id1)
						.children()
						.next()
						.css('display') == 'block'
				) {
					$('.r' + id1)
						.children()
						.show()
					$('.r' + id1)
						.children()
						.next()
						.css('display', 'none')
				} else {
					$('.r' + id)
						.children()
						.hide()
					$('.r' + id)
						.children()
						.next()
						.css('display', 'block')
				}
				id = id - 1
			}
		})
	},
}

export default backendHandlers

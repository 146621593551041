/* eslint-disable no-new */
import Swiper from 'swiper'

const productSlider = {
  init() {
    this.$slider = $('.js-product-slider')
    this.initSlider()
  },

  initSlider() {
    // eslint-disable-next-line no-new
    new Swiper(this.$slider, {
      direction: 'horizontal',
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: '.slider-pagination',
        type: 'bullets'
      }
    })
  }
}

const introSlider = {
  init() {
    this.$slider = $('.js-slider-intro')
    this.initSlider()
  },

  initSlider() {
    // eslint-disable-next-line no-new
    new Swiper(this.$slider, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      autoplay: {
        delay: 7000
      },
      pagination: {
        el: '.slider-pagination',
        type: 'bullets'
      }
    })
  }
}

const cardsSlider = {
  init() {
    this.$slider = $('.js-slider-cards')
    this.initSlider()
  },

  initSlider() {
    // eslint-disable-next-line no-new
    new Swiper(this.$slider, {
      loop: true,
      autoplay: {
        delay: 2000
      },
      slidesPerView: 2.2,
      spaceBetween: 8,
      watchSlidesVisibility: true,
      pagination: {
        el: '.slider-pagination',
        type: 'bullets'
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        540: {
          slidesPerView: 3,
          spaceBetween: 10
        }
      }
    })
  }
}

const blogSlider = {
  init() {
    this.$slider = $('.js-slider-cards-blog')
    this.initSlider()
  },

  initSlider() {
    // eslint-disable-next-line no-new
    new Swiper(this.$slider, {
      slidesPerView: 1,
      spaceBetween: 15,
      watchSlidesVisibility: true,
      pagination: {
        el: '.slider-pagination',
        type: 'bullets'
      },
      breakpoints: {
        767: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      }
    })
  }
}

const cartSlider = {
  init() {
    this.$cartSlider = $('.js-cart').find('.js-cart-slider')
    this.$cartSlider.wrap('<div class="main-basket__slider"></div>')
    this.$cartSlider.appendTo('.bx-soa__column:nth-child(2)')
    // this.$cartSlider.css({
    //   width: '100%',
    //   marginLeft: '130px'
    // })
    document.addEventListener('DOMContentLoaded', () => {
      this.eventHandler()
    })
  },
  eventHandler() {
    const self = this
    $(document).ready(() => {
      new Swiper(self.$cartSlider, {
        slidesPerView: 3,
        spaceBetween: 30,
        watchSlidesVisibility: true,
        pagination: {
          el: '.slider-pagination'
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
      })
    })
  }
}

const initSliders = () => {
  productSlider.init()
  introSlider.init()
  cardsSlider.init()
  blogSlider.init()
  cartSlider.init()
}

export default initSliders


const catalog = {
  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    const $catalog = $('.js-catalog .section-catalog__link')

    if (window.innerWidth <= 605) $catalog.addClass('js-toggle-catalog')

    // $(window).on('resize', () => {
    //   if (window.innerWidth >= 605) {
    //     $catalog.removeClass('js-toggle-catalog')
    //     $catalog.closest('.js-catalog').removeClass('active')
    //     $('.js-catalog-list').slideDown()
    //   } else {
    //     $catalog.addClass('js-toggle-catalog')
    //     $catalog.closest('.js-catalog').removeClass('active')
    //     $('.js-catalog-list').slideUp()
    //   }
    // })

    $(document).on('click', '.js-toggle-catalog', function (e) {
      e.stopPropagation()
      e.stopImmediatePropagation()
      const $list = $(this).closest('.js-catalog').children('.js-catalog-list')
      $(this).closest('.js-catalog').toggleClass('active')
      $list.slideToggle()
    })
  }
}

export default catalog

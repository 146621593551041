const common = {
  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    /* Кнопка "добавить в избранное" - перемещена в backend/custom.js */
    // $('.js-like-btn').each(function() {
    //   $(this).on('click', function() {
    //     $(this).toggleClass('like-btn--active')
    //   })
    // })

    /* */
    $(document).on('click', '.js-show-more', function() {
      $(this).toggleClass('active')
      const isOpen = $(this).hasClass('active')
      $(this)
        .siblings('.js-review-description')
        .toggleClass('active')
      $(this).text(isOpen ? 'Скрыть' : 'Читать всё')
    })

    $(document).on('click', '.js-toggle-form', function() {
      $(this).toggleClass('active')
      const isOpen = $(this).hasClass('active')
      $(this)
        .parent()
        .siblings('.js-form')
        .slideToggle()
      $(this).text(isOpen ? 'Свернуть' : 'Записаться')
    })

    /* Мобильное меню */
    $(document).on('change', '.js-toggle-menu', function() {
      $('.js-header-mobile').toggleClass('active')
      $('.js-body').toggleClass('body--fix')
      $('.js-header-hide').toggle()
    })

    /* Поиск */
    $(document).on('click', '.js-search-mobile', function() {
      $('.js-search-input').fadeIn()
    })
    $(document).on('click', '.js-close-search-input', function() {
      $('.js-search-input').fadeOut()
    })
  },
}

export default common
